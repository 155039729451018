<template>
  <div class="lecturer">
    <div class=" examCont"  v-if="commonExamList.length >0">
        <div class="">
          <ul>
            <li
              v-for="item in commonExamList"
              :key="item.id"
             
            >
              <p class="exam_name">{{item.exam_name}}</p>  
              <div class="time_">
                <p>开始时间：{{item.start_date}}</p>
                <p>结束时间：{{item.end_date}}</p>
              </div>     
              <div class="down_num">
                剩余考试次数：{{item.remaining_exam_count}}次
              </div> 
              <!-- <div class="down_num">
                限制考试次数：{{item.limit_desc}}
              </div>  -->
              <div class="btn">
                <p  @click="goExam(item)">去考试</p>
                <p class="socre" @click="viewScoreBtn(item.id, item.exam_name)">查看分数</p>
              </div>    
            </li>
          </ul>
        </div>
    </div>
    
    <div v-else class="no_data">暂无数据</div>

    <pagination v-if="num>0" :num="num" :limit="limit" @getNew="getNew"></pagination>
    
    <!-- 查看分数 -->
    <div class="view-score" v-if="viewScoreState ">
        <div class="score-content">
          <div class="close" @click="viewScoreState = false"></div>
          <div class="score-title">{{ viewScoreTitle }}</div>
          <ul class="my-table">
            <li class="table-th">
              <span class="table-td" style="width: 150px">id</span>
              <span class="table-td" style="width: 200px">考试时间</span>
              <span class="table-td" style="width: 180px">分数</span>
            </li>
            <li
              class="table-tr"
              v-for="(item) in queryScore"
              :key="item.created_at"
            >
              <span class="table-td" style="width: 150px"
                >
                <!-- 第{{ index + 1 }}次考试 -->
                {{item.id}}
                </span
              >
              <span class="table-td" style="width: 200px">{{
                item.created_at
              }}</span>
              <span class="table-td" style="width: 180px">{{
                item.score
              }}</span>
              <p class="btn" @click="examDetail(item)">查看</p>
            </li>
            <li class="table-tr nodata" v-if="queryScore.length == 0">
              <span class="table-td">暂无分数</span>
            </li>
          </ul>
        </div>
      </div>
  
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'lecturer',
  data(){
    return {
      num: 0,            //数据总个数
      limit: 12,         //一页显示几个
      nowPage: 1, //当前页码     
      commonExamList:[],//公开考试列表
      viewScoreState:false,//查看分数弹框状态
      viewScoreTitle:'',
      queryScore:[],//查看分数列表
    }
  },
  components:{
    pagination
  },
  mounted(){
    this.getOpenExam();      //获取讲师列表
  },
  methods: {
    //获取公开考试列表
    getOpenExam(){
      this.$axios.post(`/v1/commonExam/myCommonExam`,{page:this.nowPage,per_page:this.limit}, { useLog: true }).then(res=>{
        if(res.code==0){
          this.commonExamList = res.data.data
          this.num = res.data.total
        }
      })
    },
     //查看分数
    viewScoreBtn(id, name) {
    
      this.$axios
        .post(`/v1/commonExam/queryScore`,
          {
            exam_id: id,
          },
          { useLog: true }
        )
        .then((res) => {
          this.viewScoreState = true;
          this.viewScoreTitle = name;
          this.queryScore = res.data;
        });
    },
     // 查看考试详情
    examDetail(row){
      this.$router.push('/userCenter/commExamDetail?id='+row.id)
    },
    // 跳转到考试
    goExam(item){
        this.$router.push('/commonExam/text/'+item.id)
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getOpenExam();
    },
  }
}
</script>
<style scoped lang="scss">
.examCont{
    // width: 1200px;
    // margin: 40px auto;
  ul{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 32%;
      background: #fff;
      box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
      border-radius: 6px;
      box-sizing: border-box;
      padding:15px;
      margin-right: 2%;
      margin-bottom: 20px;
      p.exam_name{
        font-size: 16px;
        margin-bottom: 10px;
      }
      div.time_{
        p{
          font-size: 14px;
          color: #666;
          margin-bottom: 5px;
        }
      }
      .down_num{
        font-size: 14px;
        color: #666;
      }
      div.btn{
        p{
            display: inline-block;
            background: #254ed4;
            padding: 5px 10px;
            margin-top: 10px;
            cursor: pointer;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
        }
        p.socre{
            margin-left: 10px;
            background: #999;
            color: #fff;
        }
      }
    }
    li:nth-child(3n){
        margin-right: 0;
    }
  }
}
.page-wrap{
  padding-bottom: 30px;
}

// 查看分数
.view-score {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
 
  z-index: 99;
  .score-content {
    width: 600px;
    max-height: 600px;
    padding: 30px;
    background-color: #fff;
    position: relative;
    .score-title {
      font-size: 24px;
      font-weight: 900;
      color: #333;
      margin-bottom: 20px;
    }
    .my-table{
        max-height: 500px;
        overflow-y: auto;
    }
    .close {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-ico.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: -50px;
      top: 0;
      cursor: pointer;
    }
    p.btn{
      cursor: pointer;
      color: #254ed4;
    }
  }
}
.more_btn{
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  margin-top: 20px;
}
</style>